import React, { Component } from 'react';
import "./loader.css"
// import Loadersvg from "./loadersvg"

export default class LoaderIndex extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            <div className="cs-loader">
                <div className="cs-loader-inner">
                    <label>	●</label>
                    <label>	●</label>
                    <label>	●</label>
                    <label>	●</label>
                    <label>	●</label>
                    <label>	●</label>
                </div>
            </div>
        );
    }
}
