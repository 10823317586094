import React, { Component } from 'react';
import './App.css';
import { BrowserRouter, Route } from 'react-router-dom';
import Loadable from 'react-loadable';
import Loader from './components/Common/Loader/Index';


// const SpecialNavbar = Loadable({
//   loader: () => import("./components/Specialnavbar/Index"),
//   loading: () => <Loader />,
// });

const Navbar = Loadable({
  loader: () => import('./components/Navbar/Index'),
  loading: () => <Loader />,
});

const HomeIndex = Loadable({
  loader: () => import('./components/LandingPage/Index'),
  loading: () => <Loader />,
});

const AboutusIndex = Loadable({
  loader: () => import('./components/Aboutus/Index'),
  loading: () => <Loader />,
});

const ContactIndex = Loadable({
  loader: () => import('./components/Contact/Index'),
  loading: () => <Loader />,
});

const GetinvolvedIndex = Loadable({
  loader: () => import('./components/GetInvolved/Index'),
  loading: () => <Loader />,
});

const ResourcesIndex = Loadable({
  loader: () => import('./components/Resources/Index'),
  loading: () => <Loader />,
});

const CommunityIndex = Loadable({
  loader: () => import('./components/Community/Index'),
  loading: () => <Loader />,
});

// const ErrorIndex = Loadable({
//   loader: () => import("./components/Common/Error404/Index"),
//   loading: () => <Loader />,
// });

const InitiativesIndex = Loadable({
  loader: () => import('./components/Initiatives/Index'),
  loading: () => <Loader />,
});


// const LoaderIndex = Loadable({
//   loader: () => import("./components/Common/Loader/Index"),
//   loading: () => <Loader />,
// });


class App extends Component {
  constructor() {
    super();
    this.state = {
      pathstring: window.location.pathname.substring(1),
    };
  }

  render() {
    return (
      <BrowserRouter>
        <React.Fragment>
          {this.state.pathstring === 'aboutus' && this.state.pathstring === 'contact'
            && this.state.pathstring === 'getinvolved' && this.state.pathstring === 'resources'
            && this.state.pathstring === 'community' && this.state.pathstring === 'initiatives'
            ? <Route path="/" component={Navbar} /> : null
          }
          <Route exact path="/" component={HomeIndex} />
          <Route path="/aboutus/" component={AboutusIndex} />
          <Route exact path="/contact" component={ContactIndex} />
          <Route exact path="/getinvolved" component={GetinvolvedIndex} />
          <Route exact path="/resources" component={ResourcesIndex} />
          <Route exact path="/community" component={CommunityIndex} />
          {/* <Route exact path='/loader' component={LoaderIndex} /> */}
          <Route exact path="/initiatives" component={InitiativesIndex} />
          {/* <Route path='*' exact={true} component={ErrorIndex} /> */}
        </React.Fragment>
      </BrowserRouter>
    );
  }
}

export default App;
